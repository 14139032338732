import React from "react";

// Define properties for the component
interface StoreLinksProps { }

// Functional Component
export const StoreLinks: React.FC<StoreLinksProps> = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h2 style={{ marginBottom: "5rem" }}>دلوقتي تقدر تطلب من خلال تطبيقنا</h2>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <a
          href="https://play.google.com/store/apps"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
            alt="Play Store"
            style={{ width: "150px" }}
          />
        </a>
        <a
          href="https://www.apple.com/app-store/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
            alt="Apple Store"
            style={{ width: "150px" }}
          />
        </a>
      </div>
    </div>
  );
};
