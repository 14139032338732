import { create } from 'zustand';
import axios from '../axios';
import { useLoaderStore } from './loader';

interface IProduct {
  quantity: Number;
  name: string;
  id: string;
  originalPrice: Number;
  discount: Number;
  commissary: Number;
  menu: Number;
  exclusive: Number;
  pharmacy: Number;
  gomla: Number;
  commissaryPrice: Number;
  menuPrice: Number;
  exclusivePrice: Number;
  pharmacyPrice: Number;
  gomlaPrice: Number;
}

interface IProductStore {
  product: IProduct[];
  getProducts: () => void;
}

export const useProductStore = create<IProductStore>((set) => ({
  product: [],
  getProducts: async () => {
    useLoaderStore.getState().startLoading();
    try {
      const data = await axios.get('products');
      set({ product: data.data.data });
    } catch (e: any) {
      console.log(e.response);
    }
    useLoaderStore.getState().finishLoading();
  },
}));
