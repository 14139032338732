import React, { useEffect, useState, FC } from "react";
import classes from "./Layout.module.css";
import Container from "@mui/material/Container";
import { useProductStore } from "../../stores/products";
import { useCartStore } from "../../stores/cart";
import { useOrderStore } from "../../stores/order";
import { LogoBar } from "./../LogoBar/LogoBar";
import { useUserStore } from "../../stores/user";
import { StoreLinks } from "../../Components/StoreLinks/StoreLinks";

export interface LayoutProps {}

export const Layout: FC<LayoutProps> = (props) => {
  const getProducts = useProductStore((state) => state.getProducts);
  const getCart = useCartStore((state) => state.getCart);
  const getOrders = useOrderStore((state) => state.getOrders);
  const email = useUserStore((state) => state.user.email);

  useEffect(() => {
    getProducts();
    if (email) {
      getOrders();
      getCart();
    }
  }, []);

  return (
    <>
      <LogoBar />
      <Container
        fixed
        disableGutters
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <StoreLinks />
      </Container>
    </>
  );
};
