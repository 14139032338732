import { create } from 'zustand';
import axios from '../axios';
import { useLoaderStore } from './loader';

interface ICartProduct {
  quantity: number;
  name: string;
  productId: string;
  price: string;
  discount: string;
  before: string;
}

interface ICartStore {
  products: ICartProduct[];
  totalPrice: number;
  getCart: () => void;
  emptyCart: () => void;
  deleteItem: (id: string) => void;
  addToCart: (id: string) => void;
  addToCartWithQuantity: (id: string, quantity: number) => void;
  removeFromCart: (id: string) => void;
}

export const useCartStore = create<ICartStore>((set) => ({
  products: [],
  totalPrice: 0,
  getCart: async () => {
    try {
      const data = await axios.get('cart');
      set({
        products: data.data.data.products,
        totalPrice: data.data.data.totalPrice,
      });
    } catch (e: any) {
      console.log(e.response);
    }
  },
  addToCart: async (id) => {
    useLoaderStore.getState().startLoading();
    const data = await axios.post('cart', {
      productId: id,
      quantity: 1,
    });
    set({
      products: data.data.data.products,
      totalPrice: data.data.data.totalPrice,
    });
    useLoaderStore.getState().finishLoading();
  },
  removeFromCart: async (id) => {
    useLoaderStore.getState().startLoading();
    const data = await axios.patch('cart', {
      productId: id,
    });
    set({
      products: data.data.data.products,
      totalPrice: data.data.data.totalPrice,
    });
    useLoaderStore.getState().finishLoading();
  },
  emptyCart: () => {
    set((state) => ({ totalPrice: 0, products: [] }));
  },

  deleteItem: async (id) => {
    useLoaderStore.getState().startLoading();

    const data = await axios.patch('cart/item', {
      productId: id,
    });
    set({
      products: data.data.data.products,
      totalPrice: data.data.data.totalPrice,
    });
    useLoaderStore.getState().finishLoading();
  },

  addToCartWithQuantity: async (id, quantity) => {
    useLoaderStore.getState().startLoading();
    try {
      const data = await axios.post('cart', {
        productId: id,
        quantity,
      });
      set({
        products: data.data.data.products,
        totalPrice: data.data.data.totalPrice,
      });
    } catch (e) {
      console.log(e);
    }
    useLoaderStore.getState().finishLoading();
  },
}));
