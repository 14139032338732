import axios from "../axios";
import { create } from "zustand";

interface IUser {
  email: String;
  googleId: String;
  verified: Boolean;
  address: String;
  PharmacyName: String;
  PharmacyCode: String;
  PhoneNumber: String;
  type: String;
}

interface IUserStore {
  shouldLogin: boolean;
  shouldFillInfo: boolean;
  openLogin: boolean;
  openInfo: boolean;
  done: boolean;
  user: IUser;
  error: string;
  getMe: () => void;
  updateMe: (body: any) => void;
  setOpenLogin: (newState: boolean) => void;
  setOpenFillInfo: (newState: boolean) => void;
}

export const useUserStore = create<IUserStore>((set) => ({
  done: false,
  shouldLogin: true,
  shouldFillInfo: true,
  openLogin: true,
  openInfo: true,
  user: {} as IUser,
  error: "",
  getMe: async () => {
    set({ done: false });
    try {
      const data = await axios.get("/auth/me");
      set({ user: data.data.data });
      if (data.data.data.email) {
        set({ shouldLogin: false });
      }
      if (data.data.data.PharmacyCode || data.data.data.address) {
        set({ shouldFillInfo: false });
      }
    } catch (e: any) {
      set({ shouldFillInfo: false });
      console.log(e.reponse);
    }
    set({ done: true });
  },
  updateMe: async (body) => {
    try {
      const data = await axios.post("/user/updateMe", body);
      set({ user: data.data.data });
      if (data.data.data.PharmacyCode || data.data.data.address) {
        set({ shouldFillInfo: false });
      }
    } catch (e: any) {
      console.log(e.response.data);
      set({ error: e.response.data.message });
    }
  },
  setOpenLogin: (newState: boolean) => {
    set({ openLogin: newState });
  },
  setOpenFillInfo: (newState: boolean) => {
    set({ openInfo: newState });
  },
}));
