import React, { useEffect } from "react";
import classes from "./App.module.css";
import { Layout } from "./Containers/Layout/Layout";
import { useLocation } from "react-router-dom";
import { Admin } from "./Containers/Admin/Admin";
import { useUserStore } from "./stores/user";
import { Login } from "./Containers/Login/Login";
import { StartScreen } from "./Containers/StartScreen/StartScreen";
import { useLoaderStore } from "./stores/loader";
import { CircularProgress } from "@mui/material";
import { auth } from "./Components/FirebaseLogin/firebase";
function App() {
  const { pathname } = useLocation();
  const getMe = useUserStore((state) => state.getMe);
  const user = useUserStore((state) => state.user);
  const openLogin = useUserStore((state) => state.openLogin);
  const setOpenLogin = useUserStore((state) => state.setOpenLogin);
  const shouldShowLogin = useUserStore((state) => state.shouldLogin);
  const done = useUserStore((state) => state.done);
  const loading = useLoaderStore((state) => state.loading);

  const shouldShowStartScreen = !done;
  const showLoginNow = shouldShowLogin && !loading;
  useEffect(() => {
    async function fetchNewToken() {
      const token = await auth.currentUser?.getIdToken(true);
      if (token) localStorage.setItem("tokenId", token);
    }

    const timer = setTimeout(() => {
      fetchNewToken();
    }, 1000); // 2000 ms = 2 seconds

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (!user.email) {
      getMe();
    }
  }, [user]);

  return (
    <div className={classes.App}>
      {loading && (
        <div className={classes.SpinnerOverlay}>
          <CircularProgress size={"10rem"} className={classes.Spinner} />
        </div>
      )}
      {shouldShowStartScreen ? (
        <StartScreen />
      ) : pathname.toLowerCase().startsWith("/admin") ? (
        <>
          {showLoginNow && <Login open={openLogin} setOpen={setOpenLogin} />}
          <Admin />
        </>
      ) : (
        <>
          <Layout />
        </>
      )}
    </div>
  );
}

export default App;
