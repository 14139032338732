import { FC, useState } from "react";
import classes from "./Login.module.css";
import { GoogleButton } from "../../Components/GoogleButton/GoogleButton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { signInWithGooglePopup } from "../../Components/FirebaseLogin/firebase";
export interface LoginProps {
  setOpen: any;
  open: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
  gap: "3rem",
  borderRadius: "0.9rem",
  border: "none",
};

export const Login: FC<LoginProps> = (props) => {
  const googleAuth = async () => {
    const response = await signInWithGooglePopup();
    const getToken = await response.user.getIdTokenResult();
    localStorage.setItem("tokenId", getToken.token);
  };

  const handleClose = () => props.setOpen(false);

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ width: "90%" }}>
            برجاء تسجيل الدخول لمشاهده الخصومات المخصصه واضافه اصناف الى السله
          </h2>
          <GoogleButton click={googleAuth} />
          <button
            style={{
              color: "white",
              fontSize: "2rem",
              fontWeight: "400",
              cursor: "pointer",
              width: "60%",
              backgroundColor: "red",
              marginTop: "2rem",
              padding: "2rem",
              borderRadius: "0.9rem",
              border: "none",
            }}
            onClick={handleClose}
          >
            الغاء
          </button>
        </Box>
      </Modal>
    </>
  );
};
